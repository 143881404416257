<template>
  <div>
    <!-- 提现页面 -->
    <!-- 搜索栏 -->
    <el-form class="params-wrap" size="small" :inline="true" :model="queryParams" label-width="120px">
      <div style="border-bottom:1px solid #ccc;margin-bottom:15px;">
        <el-form-item>
          <div class="cash">
            当前余额（元）：
            <div>
              <countTo class="cashNum" :startVal='0' :endVal='reportDetail.balance' :duration='1500' decimals="2"></countTo>
              <el-button class="btn" type="primary" @click="cashHandle">提现</el-button>
            </div>
          </div>
        </el-form-item>
      </div>
      <div style="border-bottom:1px solid #ccc;margin-bottom:15px;">
        <el-form-item>
          <el-date-picker style="width:230px" size="small" v-model="timeRange" @change="activeName=='first'?getTimeRange($event,'start','end','queryParams'):getTimeRange($event,'start','end','queryParamsB'), getTimeRange($event,'start','end','queryParamsC')" type="daterange" value-format="yyyy-MM-dd" :picker-options="beforeCurTime" range-separator="-" start-placeholder="开始日期" end-placeholder="结束日期" align="right"></el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button type="success" icon="el-icon-search" @click="activeName=='first'?refresh():expendRequire(),reportRequire()">查询</el-button>
        </el-form-item>
      </div>
      <hr/>
      <el-form-item inline>
        <el-input placeholder="请输入内容" :value="reportDetail.sum" disabled>
          <template slot="prepend">总营业额：</template>
          <template slot="append">元</template>
        </el-input>
      </el-form-item>
      <el-form-item inline>
        <el-input placeholder="请输入内容" :value="reportDetail.divideMoney" disabled>
          <template slot="prepend">平台分成：</template>
          <template slot="append">元</template>
        </el-input>
      </el-form-item>
      <el-form-item inline>
        <el-input placeholder="请输入内容" :value="reportDetail.frozenSum" disabled >
          <template slot="prepend">冻结金额：</template>
          <template slot="append">元</template>
        </el-input>
      </el-form-item>
      <el-form-item inline>
        <el-input placeholder="请输入内容" :value="reportDetail.income" disabled>
          <template slot="prepend">总收入：</template>
          <template slot="append">元</template>
        </el-input>
      </el-form-item>
    </el-form>

    <div class="content-wrap">
      <el-tabs type="border-card" v-model="activeName" @tab-click="handleClick">
        <!-- 收入明细 -->
        <el-tab-pane label="收入明细" name="first">
          <div style="width: 100%;">
            <el-link :href="BaseUrl + 'net/admin/cash/noLogin/export'" class="fr" style="margin-bottom: 10px;">
              <el-button type="warning" size="small" icon="el-icon-position">导出</el-button>
            </el-link>
          </div>
          <paged-table :data="incomeList" :total="incomeTotal"
            :loading="tLoading" :isShowSelection="false"
            defaultSortProp="id" v-model="queryParams"
            @refresh="refresh">
            <el-table-column prop="payTime" label="交易时间" :show-overflow-tooltip="true">
            </el-table-column>
            <el-table-column prop="addTime" label="交易事项" :show-overflow-tooltip="true">
              <template slot-scope="scope">
                <span v-if="scope.row.orderType==1">套餐充值</span>
                <span v-if="scope.row.orderType==2">套餐续订</span>
                <span v-if="scope.row.orderType==3">套餐升级</span>
                <span v-if="scope.row.orderType==3">套餐报停</span>
                <span v-if="scope.row.orderType==5">套餐复通</span>
                <span v-if="scope.row.orderType==6">套餐变更</span>
                <span v-if="scope.row.orderType==7">号卡套餐</span>
                <span v-if="scope.row.orderType==8">手机号</span>
              </template>
            </el-table-column>
            <el-table-column prop="payType" label="交易方式" :show-overflow-tooltip="true">
              <template slot-scope="scope">
                <span v-if="scope.row.payType==1">微信支付</span>
                <span v-if="scope.row.payType==2">支付宝</span>
                <span v-if="scope.row.payType==3">余额支付</span>
                <span v-if="scope.row.payType==4">线下支付</span>
              </template>
            </el-table-column>
            <el-table-column width="120" prop="payPrice" label="订单金额（元）" :show-overflow-tooltip="true">
              <template slot-scope="scope">
                <span>{{ (scope.row.payPrice/100).toFixed(2) }}</span>
              </template>
            </el-table-column>
            <el-table-column width="120" prop="divideMoney" label="运营分成（元）" :show-overflow-tooltip="true">
              <template slot-scope="scope">
                <span>{{ (scope.row.divideMoney/100).toFixed(2) }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="content" label="商品名" :show-overflow-tooltip="true">
            </el-table-column>
            <el-table-column prop="orderNum" label="订单号" :show-overflow-tooltip="true"></el-table-column>
            <el-table-column width="120" prop="extend.addUserName" label="实际收入（元）" :show-overflow-tooltip="true">
              <template slot-scope="scope">
                <span>{{ ((scope.row.payPrice - scope.row.divideMoney)/100).toFixed(2) }}</span>
              </template>
            </el-table-column>
            <el-table-column sortable="custom" prop="status" show-overflow-tooltip label="状态" width="100">
              <template slot-scope="scope">
                <span v-if="scope.row.paiStatus==3">已到账</span>
                <span v-if="scope.row.paiStatus==1||scope.row.paiStatus==2">冻结中</span>
              </template>
            </el-table-column>
          </paged-table>
        </el-tab-pane>

        <!-- 支出明细 -->
        <el-tab-pane label="支出明细" name="second">
          <div style="width: 100%;">
            <el-link :href="BaseUrl + 'settle/shop/withdraw/noLogin/export'" class="fr" style="margin-bottom: 10px;">
              <el-button type="warning" size="small" icon="el-icon-position">导出</el-button>
            </el-link>
          </div>
          <paged-table :data="expendList" :total="expendTotal"
            :loading="tLoading" :isShowSelection="false"
            defaultSortProp="id" v-model="queryParamsB"
            @refresh="expendRequire">
            <el-table-column prop="arrivalTime" label="交易时间" :show-overflow-tooltip="true">
            </el-table-column>
            <el-table-column prop="tradeNo" label="交易号" :show-overflow-tooltip="true">
            </el-table-column>
            <el-table-column label="交易事项" :show-overflow-tooltip="true">{{'提现'}}
            </el-table-column>
            <el-table-column prop="withdrawType" label="交易方式" :show-overflow-tooltip="true">
              <template slot-scope="scope">
                <span v-if="scope.row.withdrawType==1">银行卡</span>
                <span v-if="scope.row.withdrawType==2">支付宝</span>
                <span v-if="scope.row.withdrawType==3">微信</span>
              </template>
            </el-table-column>
            <el-table-column prop="amount" label="金额（元）" :show-overflow-tooltip="true">
              <template slot-scope="scope">
                <span>{{ (scope.row.amount/100).toFixed(2) }}</span>
              </template>
            </el-table-column>
            <el-table-column sortable="custom" prop="status" show-overflow-tooltip label="状态" width="100">
              <template slot-scope="scope">
                <span v-if="scope.row.status==1">待审核</span>
                <span v-if="scope.row.status==2">待打款</span>
                <span v-if="scope.row.status==3">已打款</span>
                <span v-if="scope.row.status==-1">审核不通过</span>
              </template>
            </el-table-column>
          </paged-table>
        </el-tab-pane>
      </el-tabs>
    </div>

    <el-dialog title="提现" :visible.sync="expendVisible" width="40%" :before-close="handleClose">
      <el-form v-if="cashLast.status!=5&&cashLast.status!=-1" size="small" :inline="true" :model="cashLast" label-width="120px">
        <el-form-item v-if="cashLast.statusName" style="width: 100%; text-align: center;">
          <div style="color: red;">{{ cashLast.statusName }}</div>
        </el-form-item>
        <el-form-item label="提现时间区间">
          <el-date-picker style="width: 170px;" value-format="yyyy-MM-dd" disabled v-model="cashLast.startDate" type="date" placeholder="开始日期"></el-date-picker>
          <el-date-picker style="width: 170px;" value-format="yyyy-MM-dd" v-model="cashLast.endDate" disabled type="date" placeholder="结束日期" :picker-options="beforeCurTime"></el-date-picker>
        </el-form-item>
        <el-form-item label="提现金额">
          <el-input style="width:340px" v-model="cashLast.amount" disabled :placeholder="'共可提现 '+(cashLast.amount/100).toFixed(2)+' 元'"></el-input><span v-if="!!cashForm.amount">元</span>
        </el-form-item>
        <el-form-item label="选择开户行">
          <el-select disabled v-model="cashLast.bank" placeholder="请选择开户行" style="width:340px">
            <el-option v-for="item in bankOption" :key="item.id" :label="item.name" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="支行名称">
          <el-input disabled v-model="cashLast.branch" style="width:340px" placeholder="请输入支行名称"></el-input>
        </el-form-item>
        <el-form-item label="银行卡号">
          <el-input disabled v-model="cashLast.withdrawAccount" style="width:340px" placeholder="请输入银行卡号"></el-input>
        </el-form-item>
      </el-form>
      <el-form v-else size="small" :inline="true" :model="cashForm" label-width="120px" ref="cashFormFileds" :rules="cashFormRules">
        <el-form-item label="提现时间区间" prop="end">
            <el-date-picker style="width: 170px;" value-format="yyyy-MM-dd" @change="timeCash()" :disabled="!!startTime" v-model="cashForm.start" type="date" placeholder="开始日期" :picker-options="beforeCurTime"></el-date-picker>
          <el-date-picker style="width: 170px;" value-format="yyyy-MM-dd" @change="timeCash()" v-model="cashForm.end" type="date" placeholder="结束日期" :picker-options="beforeCurTime"></el-date-picker>
        </el-form-item>
        <el-form-item label="提现金额" prop="amount">
          <el-input style="width:340px" v-model="cashForm.amount" disabled :placeholder="'共可提现 '+(cashForm.amount/100).toFixed(2)+' 元'"></el-input><span v-if="!!cashForm.amount">元</span>
        </el-form-item>
        <el-form-item label="选择开户行" prop="bank">
          <el-select v-model="cashForm.bank" placeholder="请选择开户行" style="width:340px">
            <el-option v-for="item in bankOption" :key="item.id" :label="item.name" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="支行名称" prop="branch">
          <el-input v-model="cashForm.branch" style="width:340px" placeholder="请输入支行名称"></el-input>
        </el-form-item>
        <el-form-item label="银行卡号" prop="withdrawAccount">
          <el-input v-model="cashForm.withdrawAccount" style="width:340px" placeholder="请输入银行卡号"></el-input>
        </el-form-item>
        <el-form-item label="店铺登录密码" prop="password">
          <el-input v-model="cashForm.password" style="width:340px" placeholder="请输入店铺登录密码"></el-input>
        </el-form-item>
        <el-form-item style="width: 100%; text-align: center;">
          <el-button style="width: 150px;" type="primary" @click="cashSubmit">提现</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
/* eslint-disable no-unused-vars */
import { mapActions, mapState } from "vuex";
import PagedTable from "@/components/common/PagedTable";
import BaseImport from "@/components/common/BaseImport";
import countTo from 'vue-count-to';
import datePicker from "@/mixins/datePicker";
import pageMixin from "@/mixins/pageMixin";
import download from "@/mixins/download";
import { BaseUrl } from "@/common/config";
import { log, mergeObject } from '@/common/utils'
export default {
  name:'cashPage',
  components: {
    PagedTable,
    BaseImport,
    countTo
  },
  mixins: [pageMixin,download,datePicker],
  data() {
    return {
      BaseUrl,
      expendVisible: false,
      dialogCash: false,
      getRangeMoney: '',
      bankOption: [],
      cashForm: {
        amount: null,
        start:'',
        end:'',
        shopId: null,
        withdrawType: 3,
        withdrawAccount: '',
        bank: null,
        branch: '',
        password: '',
      },
      cashFormRules: {
        end:[{ required: true, message: "请输入截止日期", trigger: "blur" }],
        amount: [{ required: true, message: "请输入提现金额", trigger: "blur" }],
        withdrawAccount: [{ required: true, message: "请输入银行卡号", trigger: "blur" }],
        bank: [{ required: true, message: "请选择开户行", trigger: "blur" }],
        branch: [{ required: true, message: "请输入支行名称", trigger: "blur" }],
        password: [{ required: true, message: "请输入店铺密码", trigger: "blur" }],
      },
      cashLast: {
        amount: null,
        start:'',
        end:'',
        shopId: null,
        withdrawType: 3,
        withdrawAccount: '',
        bank: null,
        branch: '',
        password: '',
      },
      startTime: '',
      queryParams: {
        pageNum:'',
        pageSize:'',
        start:'',
        end:'',
        shopId: null,
      },
      activeName: 'first',
      queryParamsB: {
        pageNum:'',
        pageSize:'',
        start:'',
        end:'',
        shopId: null,
      },
      queryParamsC: {
        start:'',
        end:'',
        shopId: null,
      },
      reportDetail: {
        balance: 0,
        divideMoney: 0,
        frozenSum: 0,
        sum: 0,
        income: 0
      }, 
      details:{
        schoolName:'',
        department:'',
        extend:{},
        remark: ''
      },
      detailsB:{
        schoolName:'',
        department:'',
        extend:{},
        remark: ''
      },
      expendList: [],
      expendTotal: 0,

      pageApi:'netAdminCashGetCashList',
    };
  },
  computed: {
    ...mapState(["OA"]),
    ...mapState("storeCash", ["incomeList", "incomeTotal"]),
  },
  methods: {
    ...mapActions("storeCash",["netAdminCashGetCashList", "netAdminCashGetDisburseList", "netAdminCashGetReport", "netAdminCashGetRangeMoney", "shopWithdrawFindLastWithDraw", "shopWithdrawGetPageList", "shopWithdrawAdd"]),
    ...mapActions("commonOptions",["commonDictGetDictFPid"]),
    handleClick(tab, event) {
      if(tab.name == 'first'){ this.refresh()
      }else if(tab.name == 'second'){ this.expendRequire() }
      this.timeRange = []
    },
    async expendRequire(){
      this.tLoading = true
      try {
        let res = await this.netAdminCashGetDisburseList({...this.queryParamsB})
        this.expendList = res.list
        this.expendTotal = res.total
      }catch(e){
        log(e)
      }finally {
        this.tLoading = false;
      }
    },
    async reportRequire(){
      let res = await this.netAdminCashGetReport({...this.queryParamsC})
      this.reportDetail = res
    },
    async timeCash(){
      let obj = { shopId: this.cashForm.shopId,
        start: this.cashForm.start,
        end: this.cashForm.end, }
      let res = await this.netAdminCashGetRangeMoney(obj)
      this.cashForm.amount = (res.amount/100).toFixed(2)
      this.cashLast.amount = (res.amount/100).toFixed(2)
    },
    async cashHandle(){
      this.expendVisible = true
      this.dialogCash = true
      let res = await this.commonDictGetDictFPid({pid: 102004})
      this.bankOption = res
      let resB = await this.shopWithdrawFindLastWithDraw({})
      this.startTime = resB.next
      this.cashForm.start = resB.next
      this.cashLast = resB.item
    },
    cashSubmit(){
      let obj = {
        startDate: this.cashForm.start,
        endDate: this.cashForm.end,
        amount: this.cashForm.amount*100,
        withdrawType: this.cashForm.withdrawType,
        withdrawAccount: this.cashForm.withdrawAccount,
        bank: this.cashForm.bank,
        branch: this.cashForm.branch,
        password: this.cashForm.password,
      }
      this.$refs.cashFormFileds.validate(async (valid) => {
        if (valid) {
          let res = await this.shopWithdrawAdd(obj)
          this.refresh()
          this.$refs.cashFormFileds.resetFields();
          this.handleClose()
        } else {
          return false;
        }
      });
    },
    handleClose() {
      this.cashForm = {
        amount: null,
        start:'',
        end:'',
        shopId: null,
        withdrawType: 3,
        withdrawAccount: '',
        bank: null,
        branch: '',
        password: '',
      },
      this.expendVisible = false
      // this.$refs.cashFormFileds.resetFields();
    },
  },
  async mounted(){
    this.queryParams.shopId = sessionStorage.getItem('shopId')-0
    this.queryParamsB.shopId = sessionStorage.getItem('shopId')-0
    this.queryParamsC.shopId = sessionStorage.getItem('shopId')-0
    this.cashForm.shopId = sessionStorage.getItem('shopId')-0
    this.refresh()
    this.reportRequire()
  }
};
</script>
<style lang="scss" scoped>
.lable-num { width: 100px; text-align: center; background: #28B892; color: #fff; border-radius: 5px; display: inline-block; }
.cash {
  font-weight: bold;
  div{
    display: flex;
    align-items: center;
    .cashNum {
      display: inline-block;
      margin: 20px;
      font-size: 36px;
      font-family: DIN-Bold, DIN;
      font-weight: bold;
      color: #FF5353;
      line-height: 36px;
    }
  }
}
</style>
